import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import MarketScanGif from '../../animations/marketScan.gif';
import Layout from '../../components/Layout';
import { TextPreFooter } from '../../components/PreFooter';
import LinkButton from '../../components/LinkButton';
import { Scroll } from '../../components/Animation';
import SEO from '../../components/seo';
import styles from '../styles/market-scan.module.scss';
import PageHeader from '../../components/sections/PageHeader';
import BlueSection from '../../components/sections/BlueSection';
import { INSURANCE_TYPES_NAMES } from '../../constants';
import { INSURANCE_TYPES_ICONS_MIN } from '../../constants/insurance-icons';
import {
  GET_STARTED_LIFE_ROUTE,
  INSURANCE_TYPES_ROUTE,
} from '../../constants/routes';
import classNames from 'classnames';

// here your offer boss section
const Section1 = () => {
  return (
    <div className={styles.sectionDark}>
      <div className={styles.imgContainer}>
        <img className={styles.msImg} src={MarketScanGif} />
      </div>
      <div className={styles.textRight}>
        <div className={styles.title}>
          Get insurance that works <br /> for you and your budget.
        </div>
        <p>
          Our 'Market Scan' feature helps you quickly check if you can get a
          better deal by comparing what insurance companies can offer you.
        </p>
        <p>
          It’s not just about price - we compare features of each policy to help
          you choose the best cover for you. You get to see what you’re covered
          for and learn what you can make a claim on.
        </p>
        <LinkButton href={INSURANCE_TYPES_ROUTE} className={styles.link}>
          Learn more about types of insurance
          <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
        </LinkButton>
      </div>
    </div>
  );
};

// how it works section
const Section2 = ({ step1Img, step2Img, step3Img }) => {
  return (
    <BlueSection wrapClassName={styles.sectionBlue}>
      <Scroll>
        <h1 className={styles.title}>How it works</h1>
      </Scroll>
      <Scroll>
        <div className={styles.stepsContainer}>
          <div className={styles.step}>
            <div className={styles.stepImgContainer}>
              <Image fluid={step1Img} className={styles.stepImg1} />
            </div>
            <p>1. Answer a few simple questions</p>
          </div>
          <div className={styles.arrow}>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
          <div className={styles.step}>
            <div className={styles.stepImgContainer}>
              <Image fluid={step2Img} className={styles.stepImg2} />
            </div>
            <p>2. Market Scan finds you better covers & prices fast</p>
          </div>
          <div className={styles.arrow}>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
          <div className={styles.step}>
            <div className={styles.stepImgContainer}>
              <Image fluid={step3Img} className={styles.stepImg3} />
            </div>
            <p>3. View your comparison results online in minutes</p>
          </div>
        </div>
      </Scroll>
      <Scroll className={styles.button}>
        <LinkButton
          className={styles.button}
          href={GET_STARTED_LIFE_ROUTE}
          background="#54A702"
        >
          Get started - it’s free
        </LinkButton>
      </Scroll>
    </BlueSection>
  );
};

const Section3 = () => {
  const availableMsType = [
    {
      icon: INSURANCE_TYPES_ICONS_MIN[INSURANCE_TYPES_NAMES.LIFE],
      label: 'Life',
    },
    {
      icon: INSURANCE_TYPES_ICONS_MIN[INSURANCE_TYPES_NAMES.HEALTH],
      label: 'Health',
    },
    {
      icon: INSURANCE_TYPES_ICONS_MIN[INSURANCE_TYPES_NAMES.TRAUMA],
      label: 'Trauma',
    },
    {
      icon: INSURANCE_TYPES_ICONS_MIN[INSURANCE_TYPES_NAMES.CANCER],
      label: 'Cancer',
      className: styles.lifeIconStroke,
    },
    {
      icon: INSURANCE_TYPES_ICONS_MIN[INSURANCE_TYPES_NAMES.INCOME],
      label: (
        <>
          Income
          <br /> Protection
        </>
      ),
    },
    {
      icon: INSURANCE_TYPES_ICONS_MIN[INSURANCE_TYPES_NAMES.MORTGAGE],
      label: (
        <>
          Mortgage
          <br /> Repayment
        </>
      ),
    },
  ];
  const bulletPoints = [
    'Compare Life, Health, Trauma, Cancer and more...',
    'No need to fill out multiple quote forms to get quotes across insurers',
    'Do your comparisons all at once and in one place',
  ];
  return (
    <Scroll>
      <div className={styles.sectionWhite}>
        <Scroll>
          <h1 className={styles.title}>
            Let Market Scan work for you. Save time and money today!
          </h1>
        </Scroll>
        <Scroll>
          <h2>
            Don’t waste your time and effort entering your information across
            multiple insurance websites to just compare prices. Shop smart with
            Quashed.
          </h2>
        </Scroll>

        <Scroll>
          <div className={styles.msIconsContainer}>
            {availableMsType.map((msType, idx) => {
              const Icon = msType.icon;
              return (
                <div key={idx} className={styles.insuranceType}>
                  <LinkButton
                    className={styles.iconWrapper}
                    href={GET_STARTED_LIFE_ROUTE}
                    eventName="ms-type-button"
                  >
                    <div
                      className={classNames(styles.lifeIcon, msType.className)}
                    >
                      <Icon />
                    </div>
                  </LinkButton>
                  <p>{msType.label}</p>
                </div>
              );
            })}
          </div>
        </Scroll>

        <ul className={styles.bulletPointList}>
          {bulletPoints.map((item, index) => (
            <Scroll key={index}>
              <li className={styles.bulletPoint}>
                <i className="fa-solid fa-check"></i>
                <div className={styles.bulletPointText}>{item}</div>
              </li>
            </Scroll>
          ))}
        </ul>
      </div>
    </Scroll>
  );
};

const MarketScan = () => {
  const images = useStaticQuery(
    graphql`
      query {
        coins: file(relativePath: { eq: "coins.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        coinsMobile: file(relativePath: { eq: "coins-m.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        msStep1: file(relativePath: { eq: "market-scan/ms-step-1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        msStep2: file(relativePath: { eq: "market-scan/ms-step-2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        msStep3: file(relativePath: { eq: "market-scan/ms-step-3.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );
  return (
    <Layout
      className={styles.life}
      transparent={true}
      footerClassName={styles.footer}
      signUpUrl={GET_STARTED_LIFE_ROUTE}
    >
      <SEO title="Market Scan" />
      <PageHeader
        title="Shop & save on life insurance"
        subtitle="We help Kiwis find the best insurance deals"
        image={
          <>
            <Image
              className={styles.headerImage}
              fluid={images.coins.childImageSharp.fluid}
            />
            <Image
              className={styles.headerImageMobile}
              fluid={images.coinsMobile.childImageSharp.fluid}
            />
            <div className={styles.lifeSubheaderWrap}>
              <div className={styles.lifeSubheader}>
                <span>• Life</span>
                <span>• Health</span>
                <span>• Mortgage Repayment</span>
                <br />
                <span>• Trauma</span>
                <span>• Cancer</span>
                <span>• Income Protection</span>
              </div>
            </div>
          </>
        }
        buttonText="Get started for free"
        buttonLink={GET_STARTED_LIFE_ROUTE}
        className={styles.header}
        wrapClassName={styles.headerWrap}
        leftClassName={styles.leftHeader}
      />
      <Section1 />
      <Section2
        step1Img={images.msStep1.childImageSharp.fluid}
        step2Img={images.msStep2.childImageSharp.fluid}
        step3Img={images.msStep3.childImageSharp.fluid}
      />
      <Section3 />
      <TextPreFooter
        subTitleTop="Quashed"
        title="Helping Kiwis save money on insurance"
        buttonText="Start saving today"
        link={GET_STARTED_LIFE_ROUTE}
      />
    </Layout>
  );
};

export default MarketScan;
